import React from "react";
import { Link } from "react-router-dom";

const Footer: React.FC = () => {
  return (
    <footer className="bottom-0 bg-yellow-camille dark:darkbg">
      <div className="container mx-auto py-16 px-4 md:px-8 lg:px-16 xl:px-32">
        <div className="flex flex-col md:flex-row justify-between items-center space-y-8 md:space-y-0">
          <div className="space-y-4 text-center sm:text-left">
            <p className="font-noto uppercase dark:text-white text-2xl">
              So One
            </p>
            <div className="pt-12 ">
              <Link
                to="/about"
                className="dark:text-white text-center font-bold hover:underline"
              >
                Paramètre des cookies
              </Link>
            </div>
          </div>
          <div className="flex flex-row gap-4 ">
            <div className="dark:text-white flex flex-col sm:w-48 w-32 space-y-4">
              <span className="dark:text-yellow-camille font-black text-clear-modred">
                Ressources
              </span>
              <Link to="/goals" className="dark:text-white hover:underline">
                Objectifs
              </Link>
            </div>

            <div className="dark:text-white flex flex-col sm:w-48 w-32 space-y-4">
              <span className="dark:text-yellow-camille font-black text-clear-modred">
                Contact
              </span>
              <Link to="/about" className="dark:text-white hover:underline">
                A propos
              </Link>
              <Link
                to="/references"
                className="dark:text-white hover:underline"
              >
                Références
              </Link>
            </div>
          </div>
        </div>

        <div className="font-thin container justify-center mt-16">
          <div className="border-t-2 dark:border-white border-black border-opacity-60 w-full py-12 pb-24 text-xs flex flex-col md:flex-row gap-4 md:gap-0 justify-between items-center">
            <p className="dark:text-white">
              2024, SO ONE. Tous droits reservés.
            </p>
            <div className="flex flex-row gap-4">
              <Link to="/privacy" className="dark:text-white hover:underline">
                Politique de confidentialité
              </Link>
              <p className="dark:text-white hover:underline">
                Termes et services
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
