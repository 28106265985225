import React from "react";
import TeamMember from "../../components/props/TeamMember";
import Louis from "../../assets/team/louis.jpg";

import Catherine from "../../assets/team/catherine.jpg";

const teamData = [
  {
    name: "Catherine Legal",
    role: "Cheffe d'entreprise",
    email: "catherine.legal01@gmail.com",
    photo: Catherine,
    description:
      "Catherine Legal intègre le programme Startup d'HEC, au cours duquel elle développe et valide son concept devant un jury. L'objectif de sa start-up : répondre à l'article 10 de l'OMS (Organisation Mondiale de la Santé).sante) : mettre les connaissances à la disposition du plus grand nombre de personnel médical dans le monde. C'est ainsi que naît SO-ONE (anciennement One-Science). Entre 2000 et 2018, elle et sa société ont enregistré plus de 150 congrès au contenu hautement scientifique à travers le monde, soit 9 000 heures d'enregistrement sauvegardées sur support numérique.",

    imagePosition: "left",
    linkedin: "https://www.linkedin.com/in/catherine-legal-3433b961/",
  },
  {
    name: "Louis Giguet",
    role: "Responsable IT",
    email: "louis.giguet@protonmail.com",
    photo: Louis,
    description:
      "Diplômé d'EPITECH, Louis est un expert en informatique gérant toute la partie informatique et structurelle de SO ONE",

    imagePosition: "left",
    linkedin: "https://www.linkedin.com/in/catherine-legal-3433b961/",
  },
];

const Team: React.FC = () => {
  return (
    <section className="w-full p-4 sm:p-8 md:p-12 lg:p-16">
      <div
        id="Team"
        data-nav-color="bg-purple-camille"
        className="flex flex-col justify-center w-full rounded-3xl py-6 px-4 md:px-8 text-white
		   bg-gradient-to-tr dark:to-red-camille dark:from-header from-clear-header to-purple-camille"
      >
        <h2 className="font-noto text-2xl uppercase mb-6 text-center">
          Notre équipe
        </h2>
        <ul className="list-none grid grid-cols-1 md:grid-cols-2 gap-8">
          {teamData.map((member, index) => (
            <TeamMember
              key={index}
              name={member.name}
              role={member.role}
              email={member.email}
              description={member.description}
              photo={member.photo}
              linkedin={member.linkedin}
            />
          ))}
        </ul>
      </div>
    </section>
  );
};

export default Team;
