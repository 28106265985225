import React from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Header from "./Header";
import Carroussel from "./Carroussel_Partners";
import Carroussel_Labs from "./Carroussel_Labs";
import WorldMapSection from "./WorldMapSection";
import CongressesSection from "./CongressSection";

const References: React.FC = () => {
	return (
		<div className="bg-white dark:bg-background overflow-x-hidden w-full">
			<Navbar />
			<Header />
			<Carroussel />
			<WorldMapSection />
			<Carroussel_Labs />
			<CongressesSection />
			<Footer />
		</div>
	);
};
export default References;
