import React from "react";

const Header: React.FC = () => {
  return (
    <div className="pt-32">
      <section className="py-16 bg-yellow-camille overflow-hidden">
        <div className="relative container mx-auto px-10 md:px-12 lg:px-32 flex flex-col items-center justify-center text-center text-white">
          <div className="flex-col w-full md:w-1/2">
            <h2 className="uppercase text-black font-bold dark:text-white text-2xl md:text-3xl xl:text-4xl mb-8 animate-fadeInUp">
              Références
            </h2>
            <p className="text-black text-lg md:text-xl xl:text-2xl mb-8 animate-fadeInUp">
              Nous intervenons et collaborons en partenariat avec des sociétés
              savantes, des universités, des laboratoires, des industriels et
              des médecins. De la mise à disposition des publications d'hier à
              l'enregistrement des publications de demain.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Header;
