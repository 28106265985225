import React from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Team from "./Team";
import Header from "./Header";
import References from "./References";
import Projects from "./Projects";

const Homepage: React.FC = () => {
	return (
		<div className="bg-white dark:bg-background overflow-x-hidden w-full">
			<Navbar />
			<Header />
			<References />
			<Projects />
			<Team />
			<Footer />
		</div>
	);
};
export default Homepage;
