import React from "react";
import { Link } from "react-router-dom";

const Projects: React.FC = () => (
  <div
    id="projects"
    className="section flex items-center justify-center h-mockup overflow-hidden"
    data-nav-color="bg-yellow-camille"
  >
    <div className="container md:px-12 lg:px-32 flex flex-col md:flex-row items-start md:items-center justify-center text-left text-white">
      <div className=" mb-8 md:mb-0">
        <h2 className="text-black font-bold dark:text-white text-lg md:text-2xl lg:text-3xl mb-4">
          Afin de poursuivre dans cette voie, So One lance le projet Alexandria
        </h2>
        <p className="text-black dark:text-white   text-lg md:text-2xl lg:text-3xl  mb-8 py-8">
          Visant à créer une plateforme de contenus à haute valeur scientifique
          accessible à tous, centralisée, sécurisée et pérenne.
        </p>
      </div>
      <Link
        to="projects"
        className="inline-block mt-4 md:mt-0 md:ml-12 bg-black text-white rounded-xl px-6 py-3 font-semibold text-lg border-black border-2 hover:border-transparent hover:bg-blue-500 hover:text-white transition-colors duration-200"
      >
        Découvrir nos projets
      </Link>
    </div>
  </div>
);

export default Projects;
