import React from "react";
import Pfizer from "../../assets/partners/pfizer.png";
import Baxter from "../../assets/partners/baxter.png";
import UCB from "../../assets/partners/Ucb.png";
import AirLiquide from "../../assets/partners/AIR_LIQUIDE.png";
import Novartis from "../../assets/partners/novartis.png";

const partnersData = [
	{
		image: Pfizer,
		description: "Pfizer",
	},
	{
		image: Baxter,
		description: "ESICM (Société européenne de médecine intensive).",
	},
	{
		image: UCB,
		description:
			"WADEM (Association mondiale pour la médecine d'urgence et de catastrophe).",
	},
	{
		image: AirLiquide,
		description: "SRM Institute of Science and Technology (Inde).",
	},
	{
		image: Novartis,
		description: "American College of Chest Physicians (Orlando).",
	},
];

const Carroussel: React.FC = () => {
	return (
		<div className="bg-white py-16 sm:py-24">
			<div className="mx-auto max-w-7xl px-6 lg:px-8">
				<h2 className="text-center text-xl font-semibold leading-8 text-gray-900">
					Entre autres partenariats
				</h2>
				<div className="mt-10 grid grid-cols-2 gap-6 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5">
					{partnersData.map((partner, index) => (
						<div
							key={index}
							className="flex flex-col items-center p-4 bg-white border rounded-lg shadow-lg transition duration-300 transform hover:-translate-y-2"
						>
							<img
								className="max-h-20 w-full object-contain mb-4"
								src={partner.image}
								alt={`partner-${index}`}
							/>
							<p className="text-sm text-center">{partner.description}</p>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default Carroussel;
