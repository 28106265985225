import React from "react";

const Header: React.FC = () => {
  return (
    <header className="relative py-16">
      <section className="py-12 bg-gradient-to-br  overflow-hidden">
        <div className="relative container mx-auto px-10 md:px-12 lg:px-32 flex flex-col items-center justify-center text-center text-white">
          <div className="flex-col w-full md:w-1/2">
            <h2 className="uppercase text-black font-bold dark:text-white text-2xl md:text-3xl xl:text-4xl mb-8 animate-fadeInUp">
              Enregistrer
            </h2>
            <p className="text-black text-lg md:text-xl xl:text-2xl mb-8 animate-fadeInUp">
              Le contenu scientifique de haut niveau disponible dans les congrès
              internationaux. Créée en 2000, la société So-One a déjà enregistré
              plus de 9000 heures de contenu, dont l'implication de 700 leaders
              d'opinion, à travers le monde : Rome, Prague, Berlin, Orlando,
              Bangkok, Bruxelles, Monastir, Barcelone ou Paris... Et elle
              continuerà à le faire dans le cadre des prochains symposiums
              internationaux.
            </p>
          </div>
        </div>
      </section>
      <section className="py-12 bg-gradient-to-br from-indigo-100 to-red-100 overflow-hidden">
        <div className="relative container mx-auto px-10 md:px-12 lg:px-32 flex flex-col items-center justify-center text-center text-white">
          <div className="flex-col w-full md:w-1/2">
            <h2 className="uppercase text-black font-bold dark:text-white text-2xl md:text-3xl xl:text-4xl mb-8 animate-fadeInUp">
              Rapporter
            </h2>
            <p className="text-black text-lg md:text-xl xl:text-2xl mb-8 animate-fadeInUp">
              Les avancées technologiques, les progrès scientifiques, les
              interviews de leaders d'opinion, les études de haute valeur
              scientifique collectées par So One et menées par les sociétés
              savantes du monde entier. Partager l'actualité scientifique, que
              ce soit par des articles, des interviews, des études ou des
              travaux de vulgarisation à destination d'un public plus large.
            </p>
          </div>
        </div>
      </section>
      <section className="py-12 bg-gradient-to-br overflow-hidden">
        <div className="relative container mx-auto px-10 md:px-12 lg:px-32 flex flex-col items-center justify-center text-center text-white">
          <div className="flex-col w-full md:w-1/2">
            <h2 className="uppercase text-black font-bold dark:text-white text-2xl md:text-3xl xl:text-4xl mb-8 animate-fadeInUp">
              Reconstituer
            </h2>
            <p className="text-black text-lg md:text-xl xl:text-2xl mb-8 animate-fadeInUp">
              La base de données scientifique actuelle sur des sujets tels que
              les pandémies, la médecine d'urgence, les maladies rares, les
              maladies cardiovasculaires en sensibilisant les médecins du monde
              entier à l'avancée des recherches, en déployant le plus largement
              possible les connaissances acquises au cours des vingt dernières
              années, en établissant des check-lists d'informations à remplir
              concernant ces maladies.
            </p>
          </div>
        </div>
      </section>
    </header>
  );
};

export default Header;
