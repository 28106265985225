import React from "react";

const STYLE_CARDS = "rounded-3xl border-2 p-12";
const STYLE_H3 = "uppercase text-base xs:text-lg md:text-xl font-black py-4";

const Alexandria: React.FC = () => {
  return (
    <section className="py-48 bg-white">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <h2 className="uppercase text-black font-bold dark:text-white text-2xl md:text-3xl xl:text-4xl mb-8 animate-fadeInUp">
          Le projet <span className="text-red-camille">So One</span> Alexandrie
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 mb-8">
          {/* Card 1 */}
          <div className={STYLE_CARDS}>
            <h3 className={STYLE_H3}>Mission</h3>
            <p className="text-lg">
              Établir la bibliothèque de données la plus complète, sûre,
              centralisée et permanente possible.
            </p>
          </div>
          {/* Card 2 */}
          <div className={STYLE_CARDS}>
            <h3 className={STYLE_H3}>Inspiration</h3>
            <p className="text-lg">
              Nom inspiré de la bibliothèque d'Alexandrie, visant à rassembler
              les livres du monde entier en un seul lieu.
            </p>
          </div>
          {/* Card 3 */}
          <div className={STYLE_CARDS}>
            <h3 className={STYLE_H3}>Durabilité</h3>
            <p className="text-lg">
              Contrairement à l'ancienne bibliothèque, la bibliothèque So-One
              Alexandrie vise à perdurer pour l'humanité.
            </p>
          </div>
          {/* ... Additional cards as needed */}
        </div>
        {/* Additional content */}
        <div className="text-lg md:text-xl xl:text-2xl mb-8">
          <p>
            L'engagement du projet est de rendre son contenu accessible à tous,
            en partenariat avec les sociétés savantes.
          </p>
          <p className="mt-4">
            La garantie du projet est de mettre en ligne un contenu scientifique
            fiable, vérifié et sécurisé.
          </p>
          <p className="mt-4">
            Le contrat du projet avec les médecins et chercheurs est de fournir
            les meilleurs outils pour l'éducation et la lutte contre les
            maladies.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Alexandria;
