import React from "react";
import { motion } from "framer-motion"; // Assurez-vous d'installer framer-motion avec npm install framer-motion

const CongressesSection: React.FC = () => {
  return (
    <section className="py-20 bg-gradient-to-r   text-center">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h2 className="uppercase text-black font-bold dark:text-white text-2xl md:text-3xl xl:text-4xl mb-8 animate-fadeInUp">
            Tous les congrès enregistrés par l'entreprise sont disponibles dans
            la boutique en ligne.
          </h2>
        </motion.div>
        <div className="max-w-2xl mx-auto">
          <ul className="list-disc list-inside text-lg md:text-xl xl:text-2xl  space-y-4">
            <motion.li
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              Le congrès WADEM (World Association for Disaster and Emergency
              Medicine), organisé par l'ancien secrétaire général des Nations
              unies Boutros Boutros-Ghali et le célèbre industriel français
              Serge Dassault.
            </motion.li>
            <motion.li
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.3 }}
            >
              Les congrès JAVA
            </motion.li>
            <motion.li
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              Les congrès SEPSIS
            </motion.li>
            <motion.li
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.5 }}
            >
              Les congrès ISICEM
            </motion.li>
            <motion.li
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.6 }}
            >
              Les congrès ESICM
            </motion.li>
            <motion.li
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.7 }}
            >
              Les congrès de l'European Conference on Pediatric and Neonatal
              Ventilation (conférence européenne sur la ventilation pédiatrique
              et néonatale)
            </motion.li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default CongressesSection;
