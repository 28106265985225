import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Homepage from "./pages/homepage";
import NotFound from "./pages/notfound";
import Goals from "./pages/goals";
import References from "./pages/references";
import Projects from "./pages/projects";
import Blog from "./pages/blog";

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/goals" element={<Goals />} />
        <Route path="/references" element={<References />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/blog" element={<Blog />} />
        <Route element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default App;
