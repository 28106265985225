import React from "react";
import { Link } from "react-router-dom";

const References: React.FC = () => (
  <section
    id="references-section"
    className="py-16 bg-yellow-camille overflow-hidden"
  >
    <div className="relative container mx-auto px-10 md:px-12 lg:px-32 flex flex-row items-center justify-center text-left text-white">
      <Link
        to="references"
        className="bg-black text-white rounded-2xl px-6 py-3 font-semibold text-lg border-black border-2 hover:border-transparent hover:bg-blue-500 hover:text-white transition-colors duration-200"
      >
        Découvrir nos références
      </Link>
      <div className="flex-col justify-between text-right">
        <h2 className="text-black font-bold dark:text-white text-lg md:text-2xl lg:text-3xl  mb-8">
          Depuis 20 ans, So One travaille main dans la main avec les références
          scientifiques
        </h2>
        <span className="text-black dark:text-white text-right   text-lg md:text-2xl lg:text-3xl mb-8 py-8">
          Les leaders d'opinion reconnus par leurs pairs et les sociétés
          savantes qui ont révolutionné l'approche de la médecine ces dernières
          années. Une description de leur parcours, de leur domaine d'activité
          et de leur contribution à la communauté scientifique est disponible
          dans la rubrique "Références".
        </span>
      </div>
    </div>
  </section>
);

export default References;
