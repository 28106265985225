import React from "react";
import WorldMapSVG from "../../assets/general/wolrdmap.png";

const WorldMapSection: React.FC = () => {
  return (
    <section className="py-20 bg-gradient-to-r bg-yellow-camille overflow-hidden">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 flex flex-col items-center text-center">
        <h2 className="uppercase text-black font-bold dark:text-white text-2xl md:text-3xl xl:text-4xl mb-8 animate-fadeInUp">
          Depuis 2000, So One a déjà enregistré plus de 9000 heures de contenu,
        </h2>
        <p className="text-lg md:text-xl mb-10">
          Avec l'implication de 700 leaders d'opinion, à travers le monde :
          Rome, Prague, Berlin, Orlando, Bangkok, Bruxelles, Monastir, Barcelone
          ou Paris... Et nous continuerons dans le cadre des prochains congrès
          internationaux.
        </p>
        <div className="w-full md:w-3/4 lg:w-1/2">
          <img
            className="w-full h-auto rounded-xl shadow-lg"
            src={WorldMapSVG}
            alt="World Map"
          />
        </div>
      </div>
    </section>
  );
};

export default WorldMapSection;
