import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Logo from "../assets/general/logo_SoOne-2023.png";
import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

const navigation = [
  { name: "À propos", href: "/" },
  { name: "Nos objectifs", href: "/goals" },
  { name: "Références", href: "/references" },
  { name: "Nos projets", href: "/projects" },
  { name: "Blog", href: "/blog" },
  // { name: 'Contact', href: '/contact' },
];

const Navbar: React.FC = () => {
  const [navColor, setNavColor] = useState("bg-white");
  const [intersectingSections, setIntersectingSections] = useState(
    new Set<string>()
  );

  useEffect(() => {
    const sections = document.querySelectorAll(".section");

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const sectionId = entry.target.id; // Assurez-vous que chaque section a un ID unique
          const newIntersectingSections = new Set(intersectingSections);

          if (entry.isIntersecting) {
            newIntersectingSections.add(sectionId);
          } else {
            newIntersectingSections.delete(sectionId);
          }

          setIntersectingSections(newIntersectingSections);

          // Déterminez la couleur de la barre de navigation en fonction de la section visible
          if (newIntersectingSections.size > 0) {
            const sectionColor =
              entry.target.getAttribute("data-nav-color") || "bg-white";
            setNavColor(sectionColor);
          } else {
            setNavColor("bg-white");
          }
        });
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    sections.forEach((section) => {
      observer.observe(section);
    });

    return () => {
      sections.forEach((section) => {
        observer.unobserve(section);
      });
    };
  }, [intersectingSections]);

  return (
    <Disclosure
      as="nav"
      className={`fixed w-full z-10 transition-colors duration-300 ${navColor}`}
    >
      {({ open }) => (
        <>
          <div className="max-w-5xl mx-auto px-4 sm:px-6 md:px-8 lg:px-48 py-6">
            <div className="flex justify-between">
              <Link className="flex items-center" to="/">
                <img
                  className="block h-8 w-auto lg:hidden"
                  src={Logo}
                  alt="SoOne"
                />
                <img
                  className="hidden h-12 w-auto lg:block"
                  src={Logo}
                  alt="SoOne"
                />
                <h1 className="font-bold text-3xl  ml-6 text-secondary">
                  <span className="text-primary">So</span> One
                </h1>
              </Link>

              <div className="flex items-center md:hidden">
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-800 dark:text-white hover:bg-orange-300 hover:text-gray-800 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>

              <div className="hidden md:flex md:items-center md:space-x-4 justify-end text-xs md:text-sm lg:text-base">
                {navigation.map((item) => (
                  <NavLink
                    key={item.name}
                    to={item.href}
                    className={({ isActive }) =>
                      isActive
                        ? "text-primary hover:text-clear-modpurple font-bold"
                        : "hover:text-clear-modyellow font-bold"
                    }
                  >
                    {item.name}
                  </NavLink>
                ))}
              </div>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden">
            <div className="p-12 space-y-1">
              {navigation.map((item) => (
                <NavLink
                  key={item.name}
                  to={item.href}
                  className={({ isActive }) =>
                    isActive
                      ? "px-3 py-2 rounded-md font-bold hover:underline"
                      : "px-3 py-2 rounded-md font-bold hover:underline"
                  }
                >
                  {item.name}
                </NavLink>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default Navbar;
