import React from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

const NotFound: React.FC = () => {
	return (
		<div className="bg-white overflow-x-hidden w-full">
			<Navbar />
			<section className="h-mockup flex items-center justify-center flex-col">
				<h1 className="text-2xl sm:text-3xl uppercase">
					{"404 - Page non trouvée"}
				</h1>
				<p className="dark:text-white">
					{" Désolé, la page que vous cherchez n'existe pas ou a été déplacée."}
				</p>
			</section>
			<Footer />
		</div>
	);
};

export default NotFound;
