import React from "react";
import { Link } from "react-router-dom";

const Header: React.FC = () => {
  return (
    <header className="relative pt-64 py-16 ">
      <div
        className="relative container mx-auto px-10 md:px-12 lg:px-32 flex flex-col items-center justify-center
			 text-left"
      >
        <h1 className="text-5xl uppercase font-black">
          {"So One répond à la demande de l'OMS..."}
        </h1>
        <div className="flex flex-row">
          <p className="text-black dark:text-white   text-lg md:text-2xl lg:text-3xl mr-12 mb-8 py-8">
            <span className=" ">
              {
                "...de trouver des moyens de diffuser à un plus grand nombre d'intervenants du médical, contenus à haut niveau scientifiques. "
              }
            </span>
            <span className="">So One</span> a pour but d'enregistrer,{" "}
            <span className="">de rapporter et de replanter.</span>
          </p>
        </div>
        <Link
          to="goals"
          className=" bg-black text-white rounded-2xl px-6 py-3 font-semibold text-lg border-black border-2 hover:border-transparent hover:bg-blue-500
					 hover:text-white transition-colors duration-200 justify-center items-center flex"
        >
          Pour en savoir plus
        </Link>
      </div>
    </header>
  );
};
export default Header;
