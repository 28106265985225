import React from "react";

const Articles: React.FC = () => {
  return (
    <section className="bg-white overflow-x-hidden w-full m-auto container py-48 ">
      <div className="p-16 border-2 rounded-3xl">
        <h1 className="text-3xl font-bold mb-4 uppercase text-background">
          Rencontre avec le chef d’entreprise de la société SO-ONE
        </h1>
        <ul className="list-disc pl-6 mb-4">
          Catherine Legal est chef d’entreprise de la société SO-ONE. Depuis une
          vingtaine d'années, elle parcourt le monde avec ses collaborateurs
          pour enregistrer les contenusscientifiques de haut niveau disponibles
          dans les congrès internationaux et les diffuser sous forme de DVD. La
          société SO-ONE répond à la demande de l'OMS de mettre des outils de
          formation à la disposition de tous les médecins du monde (article 10
          de la constitution de l'OMS). CatherineLegal a accepté de me recevoir
          dans les locaux de SO-ONE, situés au 20, rue du faubourg Saint-
          Honoré.Vue d'ensemble. Catherine m’accueille avec une canette de
          Coca-Cola Light et commence à me parler de ventilateur. Je reconnais
          que malgré le charmant panorama sur la ville des lumières qu'offre la
          terrasse du 7ème étage, un ventilateur ne serait pas de trop. Il fait
          chaud. Vingt-six degrés à l'ombre, selon Google. Catherine me fixe
          quelques secondes.Puis elle continuecomme si de rien n'était. Elle
          parle d'intubation, de masques à oxygène, de poliomyélite. C'est à ce
          moment-là que je réalise que j'ai peut-être manqué quelque chose. Un
          ventilateur, dans le jargon médical, est un appareil d’assistance
          respiratoire qui vise à assurer une ventilation artificielle des
          poumons lors d’une opération chirurgicale ou lorsqu’un patient souffre
          d’insuffisance respiratoire. Catherine profite de ce malentendu pour
          me faire un bref topo sur l'histoire de la réanimation. Voici les
          principales informations à retenir :
          <li>
            La médecine d'urgenceest une pratique ancienne qui remonte à
            l'Antiquité, en Égypte, vers 1550 av. -Pour remédier à l'obstruction
            des voies respiratoires, les Égyptiens pratiquaient ce qui est
            considéré comme l'ancêtre de la trachéotomie(ouverture chirurgicale
            de la trachéepour améliorer la ventilation), sans la technologie
            moderne comme le ventilateur.
          </li>
          <li>
            En 1744, un chirurgien écossais, William Tossach, pratique le
            bouche-à- bouche sur un mineurétouffé et rédigela première
            description clinique de cette pratique. Deux ans plus tard, un autre
            chirurgien écossais décrit la pratique du massage cardiaque externe.
          </li>
          <li>
            Pendant la guerre de Crimée (1853-1856), une infirmière, Florence
            Nightingale, a réussi à faire chuter drastiquement la mortalitédes
            soldats britanniques, la faisant passer de 40 % à 2 %. Comment ?
            Elle avaitcompris avant tout le mondele concept clé de la
            réanimation : la fréquence et l'intensité des observations. En
            répartissant les patients en fonction de la gravité de leurs
            blessures et rapproche les plus gravement atteints des postes de
            soins locaux. Avant Florence Nightingale, les patients étaient
            traités par ordre chronologique et non en fonction de lagravité de
            leurs blessures.
          </li>
          <li>
            -Le respirateur a été inventé dans les années 50 par des chirurgiens
            danois pour répondre à l'une des plus grandes épidémies de notre
            temps : l'épidémie de poliomyélite (maladie infectieuse aiguë et
            contagieuse causée par le poliovirus). Depuis son invention jusqu'à
            aujourd'hui, les respirateurs n'ont cesséd'être améliorés.
          </li>
          <li>
            L'histoire de la réanimation en France commence en 1954, lors de
            l'épidémie de poliomyélite. Les premières unités de réanimation
            datent de cetteépoque.
          </li>
          <li>
            Les premiers congrès internationaux sur la réanimation et la
            médecined'urgence ont lieu dans les années 90. C'est là
            qu'intervient SO-ONE. Entre 2000 et 2018, 150 congrès (plus de 9000
            heures d'enregistrement, incluant l'implication de 700 leaders
            d'opinion) ont été enregistrés par SO-ONE à travers le monde : de
            Bangkok à Steamboat Spring, en passant par Sydney, Rome, Prague,
            Berlin, Orlando, Bruxelles, Monastir, Barcelone ou Paris...
          </li>
          Chaque enregistrement donne lieu à un montage, une session correspond
          à un ou plusieurs intervenants avec un thème général, et dure entre
          quinze et trente minutes environ. Chaque congrès enregistré devient un
          DVD, vendu sur la boutique en ligne. De nombreux clients de SO-ONE
          achètent cependant l'enregistrement des congrès auxquels ils n'ont pas
          pu assister ou qu'ils souhaitent revoir lors des congrès suivants au
          stand de la société SO- ONE. Les nationalités des clients de SO-One
          sont diverses et variées, ce qui explique que le format DVD soit le
          meilleur outil pour diffuser l'information en Asie, dans les pays du
          Maghreb etc... Enfin, la diffusion en streaming est également
          disponible sur demande. La question qui me vient maintenant à l'esprit
          est de savoir comment quelqu'un comme Catherine, qui a travaillé comme
          ingénieur dans l'industrie aéronautique et a étudié la communication
          après le lycée (d'après sa description sur Linkedin), a eu l’idée de
          fonder une entreprise comme SO-ONE. SO-One est leader en matière de
          contenu de haut niveau sur la réanimation et peut fournir du contenu à
          haute valeur scientifique sur plus de 30 domaines médicaux (dont la
          pneumologie, la cardiologie, la médecine d'urgence, la septicémie...).
          Catherine range le DVD qu'elle avait retiré de la bibliothèque pour me
          le montrer, et commence à parler d'elle. Dans sa jeunesse, elle était
          très sportive. Elle a toujours été une battante, et il n'est pas
          surprenant qu'une femme ait besoin de cet esprit pour réussir dans un
          monde principalement composé d'hommes ayant une grande confiance en
          eux. Sa mère et son frère sont également des entrepreneurs. Il y a une
          culture de l'entrepreneuriat dans la famille Legal, enracinée depuis
          trois générations. Elle m'avoue être stupéfaite par la quantité
          d'archives dont nous disposons sur la seconde guerre mondiale, et son
          intérêt pour les documentaires historiques est l'une des raisons qui
          l'a poussée à créer SO-ONE. Alors qu'elle travaille dans l'industrie
          aéronautique, Catherine est approchée par Pierre Medical, une société
          française spécialisée dans la conception d'appareils d'assistance
          respiratoire (rachetée depuis par Tico International) et découvre
          l'industrie du ventilateur.Elle assiste au congrès des JIVD (Journées
          Internationales de la Ventilation à Domicile) à Lyon en 1997, puis au
          JIVD suivant un an plus tard à Orlando. Elle est stupéfaite par le
          fait que personne n'enregistre les discours des plus grands
          scientifiques de la planète dans leur domaine de compétence et sent
          que quelqu'un doit partager ce contenu à haute valeur scientifique
          dans le monde entier. Elle a donc suivi une formation au lancement
          d'une start-up à l'école de commerce de haut niveau HEC Paris et a
          finalement présenté son projet devant un panel d'investisseurs,
          d'enseignants et d'experts. Le concept de la startup SO-ONE a été
          validé à l'unanimité, ce qui est extrêmement rare. Catherine avait
          auparavant, dans son ancien emploi chez IT Ingenierie, introduit un
          concept multimédia qui permettait aux avionneurs d'avoir une vue
          tridimensionnelle lors de l'étude du plan des avions. Pour son concept
          SO-One, elle a eu l'idée d'une application permettant jusqu'à 32
          heures d'enregistrementen plusieurs langues et d'une interface DVD
          avec des contenus classés par symposiums, par thèmes, par jours de
          congrès. Évidemment, Catherine a dû travailler avec de
          nombreuxingénieurs, traducteurs, cinéastes. SO-ONE a enregistré son
          premier congrès à la demande du laboratoire UCB de Florence, en 2000 :
          le congrès annuel de l'EEACI (European Academy of Allergy and Clinical
          Immunology). De 2000 à 2018, la société SO ONE a enregistré les
          congrès AER (réanimation), les congrès JAVA (ventilation mécanique),
          les congrès JIVD (ventilation à domicile), les congrès ISICEM
          (médecine d'urgence) etc... mais aussi le dernier congrès sur les
          victimes du terrorisme (Bataclan) ou encore l'IFAD (International Fund
          for Agricole Development). Sa citation préférée ? Elle est de l'ancien
          président américain John Fitzgerald Kennedy : "Ne demandez pas ce que
          votre pays peut faire pour vous, demandez ce que vouspouvez faire pour
          votre pays".
        </ul>
      </div>
    </section>
  );
};
export default Articles;
