import React from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Alexandria from "./Alexandria";

const References: React.FC = () => {
  return (
    <div className=" w-full">
      <Navbar />
      <Alexandria />
      <Footer />
    </div>
  );
};
export default References;
